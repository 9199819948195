import { AttachMoney, FlagOutlined, Start, TrendingDown } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import { Avatar, Box, Button, Card, Grid, IconButton, Stack, Tooltip, Typography, useTheme, Badge } from "@mui/material";
import { MAX_LINE_PROCESS } from "constants";
import { toast } from "react-toastify";
import { fCurrency, fCurrencyVNDAndShorter } from "utils";
import { createPaymentPayOS } from "../../../api/payos";
import useAuth from "../../../hooks/useAuth";
import { useEffect, useRef, useState } from "react";

export const LineProcess = ({ data, registerNewNode, unregistNode, updateNodeStatus, fullWidth = false, updated, selectedNode, handleChangeSelectedItem, displayPrice, displayPriceDefault }) => {
  const {
    isAuthenticated,
    user: { email },
  } = useAuth();

  const [activeIndex, setActiveIndex] = useState(null);

  var checkFull = false;
  if (data.countListProgress == data.countRegister) checkFull = true;

  const deposit = async (item) => {
    const data = new FormData();
    data.lineIdSeq = item.bladeUnitID + "_" + item.seq;
    data.lineId = item.bladeUnitID
    data.buyerEmail = item.userEmail;
    data.amount = item.deposit;
    data.description = "Đặt cọc " + item.bladeUnitID;
    let res = await createPaymentPayOS(data);
    toast.success("Create success");
  };

  const unregist = async (item) => {
    console.log("unregist");
    // console.log(item);

    let res = await unregist({
      email: item.userEmail,
      bladeUnitID: item.bladeUnitID,
      seq: item.seq,
      token: "ABCXYZ",
    });
    toast.success("Hủy đăng ký thành công");
  };

  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setActiveIndex(null); // Deactivate when clicking outside
      handleChangeSelectedItem(null);
    }
  };

  useEffect(() => {
    // document.addEventListener('mousedown', handleClickOutside);
    if (selectedNode) {
      setActiveIndex(selectedNode.lpId);
    }
    return () => {
      // document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [selectedNode]);

  const formatName = (name, maxLength) => {
    if (name.length > maxLength) {
      const newName = name.slice(-maxLength);
      const lastSpaceIndex = newName.indexOf(" ");
      return `...${newName.slice(lastSpaceIndex)}`;
    }
    return name;
  };

  return (
    <Grid container direction={"column"} gap={2.5}>
      <Grid item container spacing={1} xs wrap="nowrap">
        {Array(fullWidth ? data.listProgress.length : MAX_LINE_PROCESS)
          .fill(0)
          .map((i, index) => {
            const value = fCurrencyVNDAndShorter(data.initPrice - data.depreciation * index);
            return (
              <Grid key={index} item xs>
                <Stack
                  sx={{
                    position: "relative",
                    height: "100%",
                    opacity: data.listProgress.length > index ? 1 : 0.0,
                  }}
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  {/* <FiberManualRecord /> */}
                  <Box
                    sx={{
                      zIndex: 10,
                      p: 1,
                    }}
                  >
                    <Box
                      sx={{
                        p: 0.5,
                        backgroundColor: "primary.lighter",
                        // backgroundColor: "#024570",
                        color: "#024570",
                        borderRadius: "5px",
                        // zIndex: 10
                      }}
                    >
                      <Typography variant="caption" fontWeight={"medium"} letterSpacing={0.5}>
                        {value}
                      </Typography>
                    </Box>
                  </Box>
                  {index !== 0 && (
                    <Box
                      sx={{
                        height: "2px",
                        width: "calc(100%)",
                        position: "absolute",
                        top: "50%",
                        backgroundColor: "primary.main",
                        left: `calc(-50%)`,
                        // transform: "translateX(50%)",
                      }}
                    ></Box>
                  )}
                </Stack>
              </Grid>
            );
          })}
      </Grid>

      <Grid item container spacing={1} xs={1} wrap="wrap" >
        {data.listProgress.map((i, index) => {
          const isEmpty = !i.userEmail;
          const isLastItem = index === data.listProgress.length - 1;
          i.isHolding = true;
          if (data.listProgress[index + 1]?.nodeProgressStatus == 'S' || data.listProgress[index + 1]?.nodeProgressStatus == 'R' || (i?.nodeProgressStatus == 'C' && i.seq != "0") || (i?.depositStatus == '0' && i.seq != "0")) {
            //alert   ( i.lpId + " " + (i).nodeProgressStatus);
            i.isHolding = false;
          }
          const isPaid = false;
          const styleObj = {
            success: {
              borderColor: "secondary.main",
              // backgroundColor: "success.lighter",
              borderWidth: 1,
            },
            pending: {
              borderColor: "secondary.main",
              // backgroundColor: "success.lighter",
              borderWidth: 1,
            },
          };

          const lastItemStyle = isPaid ? styleObj.success : styleObj.pending;

          let renderItem;
          if (isEmpty) {
            renderItem = (
              <Card
                elevation={0}
                variant="outlined"
                sx={{
                  p: 1,
                  height: "120px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderStyle: "dashed",
                  borderColor: "secondary.main",
                  //   backgroundColor: isRegisted ? "grey.200" : "white",
                  flexDirection: "column",
                  ...(isLastItem && {
                    borderWidth: 4,
                    borderStyle: "dashed",
                  }),

                }}
                onMouseOver={() => displayPrice(data.initPrice - data.depreciation * index)}
                onMouseOut={displayPriceDefault}
              >
                <IconButton
                  // disabled={isRegisted}
                  onClick={() => {
                    registerNewNode({
                      id: data.bladeUnitID,
                      seq: i.seq,
                      deposit: data.deposit,
                      lineId: data.id,
                    });
                  }}
                  // disabled={!isAuthenticated}
                >
                  <AddIcon />
                </IconButton>
              </Card>
            );
          } else {
            renderItem = (
              <Tooltip
                title={
                  <>
                    <Typography variant="subtitle" className="textProduct">
                      {i.userEmail}
                    </Typography>
                  </>
                }
              >

                <Card
                  elevation={0}
                  variant="outlined"
                  sx={{
                    position: "relative",
                    overflow: "visible",
                    px: 0.5,
                    py: 1,
                    height: "120px",
                    width: "100%",
                    maxWidth: "100%",
                    textAlign: "center",
                    backgroundColor: email === i.userEmail ? "success.lighter" : "primary.lighter",
                    border: email === i.userEmail ? "1px dashed green" : "1px solid blue",
                    borderColor: "secondary.main",
                    ...(isLastItem && lastItemStyle),
                  }}
                  className={`item ${activeIndex === i.lpId ? 'active' : ''}`}
                  onClick={() => {
                    setActiveIndex(activeIndex === i.lpId ? i.lpId : i.lpId);
                    handleChangeSelectedItem(i);
                  }}

                  onMouseOut={() => {
                    // alert("double click");
                    setActiveIndex(activeIndex === i.lpId ? null : null);
                    handleChangeSelectedItem(i);
                  }
                  }


                  ref={ref}
                >

                  <Stack alignItems={"center"}  >
                    {isLastItem && (
                      <Tooltip title="Finisher">
                        <Typography variant="caption" color="white" sx={{ fontWeight: "bold", padding: "3px", color: "white", position: "absolute", zIndex: 1000, top: -20, backgroundColor: "secondary.main" }}>
                          Finisher
                        </Typography>
                      </Tooltip>
                    )}
                    {i.isHolding && (
                      <Tooltip title="Blade">
                        <Typography variant="caption" color="white" sx={{ fontSize: "1.75rem", fontWeight: "bold", padding: "3px", color: "white", position: "absolute", zIndex: 1000, right: -13, top: -25, }}>
                          🏓
                        </Typography>
                      </Tooltip>
                    )}
                    <div class="avatar-wrapper">
                      <Avatar className="avatar pingpongAvatar" alt={i.name} src="/upload/images/avatar-1.jpg"
                        sx={{
                          width: 30, height: 30, marginTop: 0,
                          // border: "4px solid white" ,
                          //  borderColor: "secondary.main",
                        }} />
                    </div>
                    <Typography
                      variant="subtitle2"
                      sx={{
                        maxWidth: "100%",
                        // background: "#1B1833",
                        background: "#024570",
                        color: "white",
                        borderRadius: "5px",
                        paddingLeft: "4px",
                        paddingRight: "4px",
                        marginBottom: "4px",

                      }}
                      className="textProduct mt-1"
                    >
                      {formatName(i.name, 13)}
                    </Typography>

                    {
                      (!updated && email === i.userEmail && !i.depositStatus && i.seq != 0) ?
                        <Button variant="outlined" size="small" onClick={() => deposit(i)}>
                          {/* Đặt cọc */} Deposit
                        </Button>
                        : (!updated && email === i.userEmail && i.nodeProgressStatus != 'S' && i.seq == 0) ?


                          <div class="dropdown">
                            <div class="dropdown__button">Created</div>
                            <div class="dropdown__menu">
                              <div class="dropdown__item" onClick={
                                () => {
                                  if (data.listProgress[index + 1].depositStatus == "0") {
                                    alert("Bạn chỉ được phép gửi khi người kế tiếp Đã đặt cọc ")
                                    return;
                                  }
                                  i.nodeProgressStatus = 'S';
                                  updateNodeStatus(i);
                                  console.log("Before item", data.listProgress[index - 1] || "No item before");
                                  console.log("After item", data.listProgress[index + 1] || "No item after");
                                }
                              }>Sent</div>
                            </div>
                          </div>
                          //   :(   i.nodeProgressStatus == 'S' && i.seq == 0) ?

                          //   <div class="dropdown">
                          //   <div class="dropdown__button">Sent</div>

                          // </div>
                          : (!updated && email === i.userEmail && i.nodeProgressStatus == 'R' && i.seq !== 0 && !isLastItem) ?


                            <div class="dropdown">
                              <div class="dropdown__button">Received</div>
                              <div class="dropdown__menu">
                                <div class="dropdown__item" onClick={
                                  () => {
                                    if (data.listProgress[index + 1].depositStatus == "0") {
                                      alert("Bạn chỉ được phép gửi khi người kế tiếp Đã đặt cọc ")
                                      return;
                                    }
                                    i.nodeProgressStatus = "S";
                                    updateNodeStatus(i);
                                    console.log("Before item", data.listProgress[index - 1] || "No item before");
                                    console.log("After item", data.listProgress[index + 1] || "No item after");
                                  }
                                }>Sent</div>
                              </div>
                            </div>
                            : (!updated && email === i.userEmail && i.nodeProgressStatus == 'R' && isLastItem) || (email !== i.userEmail && i.nodeProgressStatus == 'R') ?
                              <div class="dropdown">
                                <div class="dropdown__button">Received</div>
                              </div>
                              : (!updated && i.nodeProgressStatus == 'S') ?


                                <div class="dropdown">
                                  <div class="dropdown__button">Sent</div>
                                </div>

                                : (i.depositStatus == '1' && i.nodeProgressStatus == 'C' && email !== i.userEmail) ?


                                  <div class="dropdown">
                                    <div class="dropdown__button">Deposited</div>
                                  </div>

                                  :
                                  (email === i.userEmail && i.depositStatus == "1" && i.nodeProgressStatus == "C") ?

                                    <div class="dropdown">
                                      <div class="dropdown__button">Deposited</div>
                                      <div class="dropdown__menu">
                                        <div class="dropdown__item" onClick={
                                          () => {
                                            if (data.listProgress[index - 1].nodeProgressStatus != "S") {
                                              alert("Bạn chỉ được phép nhận khi người trước đã gửi ")
                                              return;
                                            }
                                            i.nodeProgressStatus = "R";
                                            updateNodeStatus(i);
                                            console.log("Before item", data.listProgress[index - 1] || "No item before");
                                            console.log("After item", data.listProgress[index + 1] || "No item after");
                                          }
                                        }>Received</div>
                                      </div>
                                    </div>

                                    : <></>
                    }

                    {
                      (!updated && email === i.userEmail && i.seq != '0' && i.depositStatus === '0') ?
                        <div>
                          <Button variant="outlined" size="small" onClick={() => deposit(i)}>
                            Deposit
                          </Button>
                          <Typography variant="caption" color="white" sx={{ fontSize: "1rem", fontWeight: "bold", padding: "3px", color: "white", position: "absolute", zIndex: 1000, right: -13, top: -15, }} onClick={() => updateNodeStatus(i, "unregist")}>
                          ⛔
                          </Typography>
                        </div>
                        : <></>
                    }
                  </Stack>
                </Card>

              </Tooltip>
            );

          }
          return (
            <Grid
              item
              key={i.lpId}
              xs
              maxWidth={"200px"}
              sx={{
                maxWidth: "200px",
              }}
            >
              {renderItem}
            </Grid>
          );
        })}
        {!fullWidth && <EmptySlots data={data} />}
      </Grid>
    </Grid>
  );
};

const EmptySlots = ({ data }) => {
  return (
    <>
      {Array(MAX_LINE_PROCESS - data.listProgress.length)
        .fill(0)
        .map((_, index) => (
          <Grid
            item
            key={index}
            xs
            maxWidth={"200px"}
            sx={{
              maxWidth: "200px",
              opacity: 0.1,
            }}
          >
            <Card
              sx={{
                height: "120px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                bgcolor: "grey.300",
              }}
              elevation={0}
            ></Card>
          </Grid>
        ))}
    </>
  );
};
export const LineMoneyDetail = ({ data, price }) => {
  return (
    <Stack direction={"row"} spacing={1} justifyContent={"space-between"}>
      {/* <InfoItem icon={<FlagOutlined />} label={"Giá vợt mới"} value={fCurrency(data.endPrice)} />
      <InfoItem icon={<Start />} label={"Giá thanh lý khởi điểm"} value={fCurrency(data.initPrice)} /> */}
      <InfoItem icon={<TrendingDown />} label={"Khấu hao"} value={fCurrency(data.depreciation)} />
      <InfoItem icon={<AttachMoney />} label={"Phí sàn"} value={fCurrency(data.fee)} />    
      <InfoItem icon={<AttachMoney />} label={"Đặt cọc"} value={data.deposit ? fCurrency(data.deposit) : fCurrency(price)} /> 
      <InfoItem icon={<AttachMoney />} label={"Hoàn cọc"} value={data.moneyBack ? fCurrency(data.moneyBack) : (price ? fCurrency(price - data.depreciation -  data.fee) : fCurrency('0'))} /> 
    </Stack>
  );
};
const InfoItem = ({ icon, label, value }) => {
  const theme = useTheme();
  const ref = useRef();
  const [isChanged, setIsChanged] = useState(false);
  // useEffect(() => {
  //   if (ref && ref.current && isChanged) {
  //     ref.current.className = 'info-item splash';

  //     setTimeout(() => {
  //       ref.current.className = 'info-item';
  //     }, 1000)
  //   }
  //   setIsChanged(true);
  //   return () => {
  //     if (ref && ref.current) {
  //       ref.current.className = 'info-item';
  //     }
  //   }
  // }, [value, ref]);
  return (
    <Stack
      ref={ref}
      // className="info-item"
      component={Card}
      sx={{ px: 2, py: 1, width: "100%", backgroundColor: theme.palette.grey[100] }}
      elevation={0}
      direction={"row"}
      gap={2}
    >
      {icon}
      <Typography variant="subtitle2" color={"GrayText"}>
        {label}
      </Typography>
      <Typography variant="subtitle1" fontWeight={"bold"}>
        {value}
      </Typography>
    </Stack>
  );
};
