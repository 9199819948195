export const PERIODS = {
  "2W": "2W",
  "1M": "1M",
  "3M": "3M",
  "6M": "6M",
  "12M": "12M",
  "24M": "24M",
};

export const periods = [
  {
    value: "2W",
    label: "2 Week",
  },
  {
    value: "1M",
    label: "1 Month",
  },
  {
    value: "3M",
    label: "3 Months",
  },
  {
    value: "6M",
    label: "6 Months",
  },
  {
    value: "12M",
    label: "12 Months",
  },
  {
    value: "24M",
    label: "24 Months",
  },
];
export const periodCounts = [
  {
    value: "3",
    label: "3",
  },
  {
    value: "6",
    label: "6",
  },
  {
    value: "12",
    label: "12",
  },
];
export const lineReq = [
  {
    label: "Line",
    value: "L",
  },
  {
    label: "Yêu cầu",
    value: "R",
  },
];
export const isRubber = [
  {
    label: "Có mút",
    value: "Y",
  },
  {
    label: "Không mút",
    value: "N",
  },
];
export const registType = [
  {
    label: "Bán",
    value: "P", // Passing
  },
  {
    label: "Cho thuê (Người tạo sẽ tự động được đăng ký ở cuối)",
    value: "L", // Leasing
  },
];
export const DEFAULT_DATE_FORMAT = "DD/MM/YYYY";

export const MAX_LINE_PROCESS = 13;
