import { Box, Button, Card, CardContent, CardMedia, Grid, IconButton, Stack, TableCell, TableRow, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { LineMoneyDetail, LineProcess } from "./LineProcess";
import useAuth from "../../../hooks/useAuth";
import HoverImage from "../HoverImage";
import { useEffect, useState } from "react";
import '../styles/index.css';
import { use } from "react";
import { AddAPhoto } from "@mui/icons-material";
import UploadMultipleFileDialog from "components/upload/UploadMultiFileDialog";
import { replaceImagesForLine } from "api/line";
import { toast } from "react-toastify";


const LineRow = ({ row, registerNewNode, unregistNode, updateNodeStatus, selectedNode, updateLine, selectedLine, handleChangeSelectedItem, hightLightItem, setHightLightItem }) => {
  // Get current email
  const {
    isAuthenticated,
    user: { email },
  } = useAuth();

  const [openModalAddImage, setOpenModalAddImage] = useState(false);


  const imgTemp = "/upload/images/avatar-code.png";
  const imageDetails = [imgTemp, imgTemp, imgTemp, imgTemp];

  // if hightLightItem === row.bladeUnitID then auto srroll to this item

  useEffect(() => {
    if (hightLightItem === row.bladeUnitID) {
      document.getElementById(row.bladeUnitID).scrollIntoView();
      // make smooth scroll
      document.getElementById(row.bladeUnitID).scrollIntoView({ behavior: "smooth" });

    }
  }, [hightLightItem]);

  const handleSaveImage = async (imageUrls) => {
      setOpenModalAddImage(!openModalAddImage);
      // const lineId = row.bladeUnitID.toString();
      // const newImages = imageUrls.map((url) => ({ url, objId: lineId }));
      // await replaceImagesForLine(lineId, [...images, ...newImages]);
      toast.success("Upload success");
      // queryClient.invalidateQueries({ queryKey: ["line"] });
    };

  const [price, setPrice] = useState(0);

  const handleDisplayPrice = (data) =>{
        setPrice(data)
  }

  const handleDisplayPriceDefault = () =>{
    setPrice("");
  }

  return (
    <TableRow key={row.name} onClick={() => {
      hightLightItem === row.bladeUnitID ? setHightLightItem(null) : setHightLightItem(row.bladeUnitID);
    }} className={hightLightItem === row.bladeUnitID ? "hightlight" : ""} id={row.bladeUnitID}
    >
      <TableCell>
        <Card
          sx={{
            maxWidth: 300,
            borderRadius: "10px",
            margin: "auto",
            overflow: "visible"
          }}
        >
          {/* Product Image */}
          <Typography onClick={() => {
            // removeLineNode({
            //   bladeUnitID: row.bladeUnitID,
            //   lineId: row.id,
            // });
            updateLine({
              bladeUnitID: row.bladeUnitID,
              lineId: row.id,
            })
          }} variant="caption" color="white" sx={{ fontSize: "1rem", fontWeight: "bold", padding: "3px", color: "white", position: "absolute", zIndex: 1000, right: -13, top: -10, }} >
            ⛔
          </Typography>
          <Link to={`/line/${row.bladeUnitID}`}>
            <CardMedia
              component="img"
              image={row.bladeImageFull || "/upload/images/avatar-code.png"} // Replace with your image URL
              alt="Super Viscaria"
              sx={{
                height: 105,
                objectFit: "contain",
              }}
            />
          </Link>
          <CardContent sx={{ marginBottom: "0px !important", padding: "0px !important" }}>
            {/* Product Title */}
            <Typography
              variant="h6"
              align="left"
              sx={{
                alignItems: "left",
                color: "#fff",
                // backgroundColor: "#b1ddf0",
                backgroundColor: "#441752",
                fontWeight: "700",
                fontSize: "0.8rem",
                height: "40px",
                padding: "0px 2px",
                borderRadius: "5px",
              }}
            >
              <div sx={{width: '100%'}}>{`🗡 ${row?.bladeFullName}`}</div>
              <div sx={{width: '100%'}}>{` Giá gốc : 3.000.000 VND`}</div>
            </Typography>

            {/* Percentage */}

            <Typography
              variant="h6"
              align="left"
              sx={{
                alignItems: "left",
                color: "#fff",
                // backgroundColor: "#fad9d5",
                backgroundColor: "#AB4459",
                marginTop: "2px",
                fontWeight: "500 !important",
                fontSize: "0.8rem",
                padding: "0px 2px",
                borderRadius: "5px",
              }}
            >
              {/* &#127880;      */}
              {`🏺 Độ mới: ${row?.remainingPercent || 100}% / Không mút`}
              {/* 🎈 */}

            </Typography>
            {/* Text */}
            <Typography
              variant="h6"
              align="left"
              sx={{
                alignItems: "left",
                color: "#fff",
                // backgroundColor: "#d0cee2",
                marginTop: "2px",
                backgroundColor: "#F29F58",
                fontWeight: "500 !important",
                fontSize: "0.8rem",
                padding: "0px 1px",
                borderRadius: "5px",
              }}
            >
              {`⏲ ${row?.periodCnt} Chu kỳ /  2 Tuần`}
            </Typography>
            {/* Bottom Images */}
            <Grid container spacing={1} sx={{ marginTop: "0px", padding: "4px 0 !important" }}>
              {row.imageUrl.map((_, index) => (
                <Grid item xs={3} key={index} sx={{ marginTop: "0px", paddingTop: "0px !important" }}>
                  <Box
                    sx={{
                      width: "32px",
                      height: "32px",
                      borderRadius: "2px",
                      overflow: "hidden",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      border: "1px solid #ccc",
                      boxSizing: "border-box",
                    }}
                  >
                    <HoverImage image={_} sx={{
                      width: "30px",
                      height: "30px",
                    }} />
                  </Box>
                </Grid>
              )).concat(
                [...Array((4 - row?.imageUrl.length) < 0 ? 0 : (4 - row?.imageUrl.length) ) ].map((_, index) => (
                  <Grid item xs={3} key={index} sx={{ marginTop: "0px", paddingTop: "0px !important" }}>
                    <Box
                      sx={{
                        width: "32px",
                        height: "32px",
                        borderRadius: "2px",
                        overflow: "hidden",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        border: "1px solid #ccc",
                        boxSizing: "border-box",
                    }}
                    >
                      <IconButton
                        sx={{
                          width: 30,
                          height: 30,
                          borderRadius: 1.5,
                          backgroundColor: "white",
                          border: "1px solid #E0E0E0",
                        }}
                      onClick={() => setOpenModalAddImage(true)}
                      >
                        <AddAPhoto />
                      </IconButton>
                    </Box>
                  </Grid>
                )))}
            </Grid>
          </CardContent>
        </Card>
      </TableCell>
      <TableCell>
        <LineMoneyDetail data={row} price={price} />
        <LineProcess 
          data={row} 
          registerNewNode={registerNewNode} 
          unregistNode={unregistNode} 
          updateNodeStatus={updateNodeStatus} 
          selectedNode={selectedNode} 
          handleChangeSelectedItem={handleChangeSelectedItem} 
          displayPrice={handleDisplayPrice} 
          displayPriceDefault={handleDisplayPriceDefault} />
      </TableCell>
      <UploadMultipleFileDialog
        currentValues={row?.imageUrl}
        open={openModalAddImage}
        onClose={() => setOpenModalAddImage(false)}
        setOpen={setOpenModalAddImage}
        onSave={handleSaveImage}
      />
    </TableRow>
  );
};

export { LineRow };