import AddIcon from "@mui/icons-material/Add";
import { Avatar, Box, Card, Grid, IconButton, Stack, Typography } from "@mui/material";
import { MAX_LINE_PROCESS } from "constants";
import useAuth from "hooks/useAuth";
import { fCurrencyVNDAndShorter } from "utils";

export const LineProcessBuilder = ({ data, fullWidth = false, }) => {
  const {
    isAuthenticated,
    user: { email },
  } = useAuth();
  const formatName = (name, maxLength) => {
    if (name.length > maxLength) {
      const newName = name.slice(-maxLength);
      const lastSpaceIndex = newName.indexOf(" ");
      return `...${newName.slice(lastSpaceIndex)}`;
    }
    return name;
  };

  return (
    <Grid container direction={"column"} gap={2.5}>
      <Grid item container spacing={1} xs wrap="nowrap">
        {Array(fullWidth ? data.listProgress.length  : MAX_LINE_PROCESS)
          .fill(0)
          .map((i, index) => {
            const value = fCurrencyVNDAndShorter(data.initPrice - data.depreciationAmount * index);
            return (
              <Grid key={index} item xs>
                <Stack
                  sx={{
                    position: "relative",
                    height: "100%",
                    opacity: data?.listProgress > index ? 1 : 0.0,
                  }}
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
       
                  <Box
                    sx={{
                      zIndex: 10,
                      p: 1,
                    }}
                  >
                    <Box
                      sx={{
                        p: 0.5,
                        backgroundColor: "primary.lighter",
                        // backgroundColor: "#024570",
                        color: "#024570",
                        borderRadius: "5px",
                        // zIndex: 10
                      }}
                    >
                      <Typography variant="caption" fontWeight={"medium"} letterSpacing={0.5}>
                        {value}
                      </Typography>
                    </Box>
                  </Box>
                  {index !== 0 && (
                    <Box
                      sx={{
                        height: "2px",
                        width: "calc(100%)",
                        position: "absolute",
                        top: "50%",
                        backgroundColor: "primary.main",
                        left: `calc(-50%)`,
                        // transform: "translateX(50%)",
                      }}
                    ></Box>
                  )}
                </Stack>
              </Grid>
            );
          })}
      </Grid>

      <Grid item container spacing={1} xs={1} wrap="wrap" >
        {
          <Grid
            item

            xs
            maxWidth={"200px"}
            sx={{
              maxWidth: "200px",
            }}
          >

            <Card
              elevation={0}
              variant="outlined"
              sx={{
                position: "relative",
                overflow: "visible",
                px: 0.5,
                py: 1,
                height: "120px",
                width: "100%",
                maxWidth: "100%",
                textAlign: "center",
                backgroundColor: "primary.lighter",
                border: email === "1px solid blue",
                borderColor: "secondary.main",
                // ...(isLastItem && lastItemStyle),
              }}
            >
              <div class="avatar-wrapper">
                <Avatar className="avatar pingpongAvatar" alt={data?.registType} src="/upload/images/avatar-1.jpg"
                  sx={{
                    width: 30, height: 30, marginTop: 0,
                    // border: "4px solid white" ,
                    //  borderColor: "secondary.main",
                  }} />
              </div>
              <Typography
                variant="subtitle2"
                sx={{
                  maxWidth: "100%",
                  // background: "#1B1833",
                  background: "#024570",
                  color: "white",
                  borderRadius: "5px",
                  paddingLeft: "4px",
                  paddingRight: "4px",
                  marginBottom: "4px",

                }}
                className="textProduct mt-1"
              >
                {formatName(data?.registType, 13)}
              </Typography>
            </Card>
          </Grid>
        }
        {data?.listProgress > 0 && Array(data.listProgress - 1).fill(data).map((i, index) => {
          

          return (
            <RegistSlots />
          );
        })}
        {
          data?.registType !== "L" ?
            <Grid
              item

              xs
              maxWidth={"200px"}
              sx={{
                maxWidth: "200px",
              }}
            >

              <Card
                elevation={0}
                variant="outlined"
                sx={{
                  position: "relative",
                  overflow: "visible",
                  px: 0.5,
                  py: 1,
                  height: "120px",
                  width: "100%",
                  maxWidth: "100%",
                  textAlign: "center",
                  backgroundColor: "primary.lighter",
                  border: email === "1px solid blue",
                  borderColor: "secondary.main",
                  // ...(isLastItem && lastItemStyle),
                }}
              >
                <div class="avatar-wrapper">
                  <Avatar className="avatar pingpongAvatar" alt={'abc'} src="/upload/images/avatar-1.jpg"
                    sx={{
                      width: 30, height: 30, marginTop: 0,
                      // border: "4px solid white" ,
                      //  borderColor: "secondary.main",
                    }} />
                </div>
                <Typography
                  variant="subtitle2"
                  sx={{
                    maxWidth: "100%",
                    // background: "#1B1833",
                    background: "#024570",
                    color: "white",
                    borderRadius: "5px",
                    paddingLeft: "4px",
                    paddingRight: "4px",
                    marginBottom: "4px",

                  }}
                  className="textProduct mt-1"
                >
                  {formatName('Paper Hero', 13)}
                </Typography>
              </Card>
            </Grid> :
            <RegistSlots />
        }
        {!fullWidth && <EmptySlots data={data} />}
      </Grid>
    </Grid>
  );
};

const RegistSlots = () => {  return (<>
    <Grid
      item
      xs
      maxWidth={"200px"}
      sx={{
        maxWidth: "200px",
      }}
    >
      <Card
        elevation={0}
        variant="outlined"
        sx={{
          p: 1,
          height: "120px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderStyle: "dashed",
          borderColor: "secondary.main",
          flexDirection: "column",
        }}
      >
        <IconButton>
          <AddIcon />
        </IconButton>
      </Card>
    </Grid>
  </>
  );
};
const EmptySlots = ({ data }) => {
  return (<>

    {Array(MAX_LINE_PROCESS - data.listProgress - 1)
      .fill(0)
      .map((_, index) => (
        <Grid
          item
          key={index}
          xs
          maxWidth={"200px"}
          sx={{
            maxWidth: "200px",
            opacity: 0.1,
          }}
        >
          <Card
            sx={{
              height: "120px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              bgcolor: "grey.300",
            }}
            elevation={0}
          ></Card>
        </Grid>
      ))}
  </>
  );
};




