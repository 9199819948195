import { useContext, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import * as yup from "yup";
import { MyContext } from "../../App";
import { RhfAutocomplete } from "components/form";
import { TablePagination } from "components/table";
import {
  Box,
  Breadcrumbs,
  Button,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormControlLabel,
  RadioGroup,
  FormLabel,
  Radio,
  Checkbox
} from "@mui/material/";
import { Home, ExpandMore, CheckBox } from "@mui/icons-material";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";

import StyledBreadcrumb from "../../components/Breadcrumb";
import { LineTable } from "./components/LineTable";
import { useQuery } from "@tanstack/react-query";
import { getLines, getLinesSearch } from "api/line";
import { getBrands } from "api/brand";
import { getSearchBlades, getBladeUnitByBladeCD } from "api/blade";
import useAuth from "../../hooks/useAuth";
import SendIcon from '@mui/icons-material/Send';

import ArticleIcon from '@mui/icons-material/Article';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';

const lineValidationSchema = yup.object({
  period: yup.string().required("Required"),
  periodCnt: yup.string().required("Required"),
});

const Line = () => {

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedBrand, setSelectedBrand] = useState({ seq: 0, parent: "ROOT", brandCD: "ALL", brandName: "Tất cả" });
  const [selectedLineStatus, setSelectedLineStatus] = useState("ALL");
  const [selectedIsRubber, setselectedIsRubber] = useState('ALL');
  const [selectedIsMine, setselectedIsMine] = useState('yes');
  const context = useContext(MyContext);
  const { isAuthenticated } = useAuth();
  const [searchData, setSearchData] = useState({
    orderCode: "",
    email: "",
    brandCD: "ALL",
    lineStatus: "ALL",
    changed: false,
    isRubber: selectedIsRubber,
    isMine: selectedIsMine,
    userEmail: selectedIsMine ? localStorage.getItem("email") : "ALL",
  });

  
  useEffect(() => {
    // Hide sidebar
    context.setIsHideSidebarAndHeader(false);
    window.scrollTo(0, 0);
  });

  const methods = useForm({
    resolver: yupResolver(lineValidationSchema),
  });

  const { data: dataList, isLoading } = useQuery({
    queryKey: ["lineList", page, rowsPerPage, searchData],
    queryFn: async () => {
      // if (!selectedBrandCD) return [];
      return await getLinesSearch({
        pageNum: page,
        pageSize: rowsPerPage,
        ...searchData,
      });
    },
  });

  const { data: dataBrand } = useQuery({
    queryKey: ["brandList"],
    queryFn: async () => {
      return getBrands();
    },
  });

  //  const lines = dataList?.data || [];
  //   const x= dataBrand;
  // debugger
  //   const brands = [{ seq: 0, parent: "ROOT", brandCD: "ALL", brandName: "Tất cả" }, ...dataBrand?.data] || [];
  const brands = dataBrand?.data || [];

  const { total, lines, selectingLine } = useMemo(() => {
    // count run
    if (dataList) {
      return { total: dataList?.data.totalResults, lines: dataList?.data.pageResults, selectingLine: dataList?.data.selectingLine };
    }

    return { total: 0, lines: [], selectingLine: "" };
  }, [dataList]);

  console.log(lines)

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setPage(1);
  };

  const handleSearch = (event) => {
    event && event.preventDefault();

    setSearchData({
      ...searchData,
      brandCD: selectedBrand.brandCD,
      lineStatus: selectedLineStatus,
      changed: !searchData.changed,
      isMine: selectedIsMine,
      isRubber: selectedIsRubber,
      userEmail: selectedIsMine ? localStorage.getItem("email") : "ALL",
    })
  }

  const handleLineStatusChange = (event) => {
    setSelectedLineStatus(event.target.value);
  };
  const handleIsRubberChange = (event) => {
    setselectedIsRubber(event.target.value);
  };

  // const { data: blades, isLoading: isLoadingBlades } = useQuery({
  //   queryKey: ["bladeList", selectedBrandCD],
  //   queryFn: async () => {
  //     if (!selectedBrandCD) return [];
  //     const response = await getSearchBlades({ brandCD: selectedBrandCD });
  //     return response.data || [];
  //   },
  //   enabled: !!selectedBrandCD,
  // });

  // useEffect(() => {
  //   setValue("bladeCD", "");
  // }, [selectedBrandCD, setValue]);

  // Nếu brand có sub brand, setSubBranchVal
  // const handleClickBrand = async (event) => {
  //   try {
  //     setBrandVal(event.target.value);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // Popup
  const [showModalPopupAdd, setShowModalPopupAdd] = useState(false);
  // const [lineID, setLineID] = useState(null);
  const openPopupAdd = () => {
    setShowModalPopupAdd(!showModalPopupAdd);
    // setLineID(lineID);
  };

  return (
    <>
      <section className="right-content w-100 h-100">
        {/* <div className="card shadow border-0 w-100 flex-row p-4">
          <h5 className="mb-0">Line List</h5>
          <Breadcrumbs aria-label="breadcrumb" className="ml-auto breadcrumbs_">
            <StyledBreadcrumb component="a" href="#" label="Dashboard" icon={<Home fontSize="small" />} />
            <StyledBreadcrumb label="Lines" deleteIcon={<ExpandMore />} />
          </Breadcrumbs>
        </div> */}

        <div className="card shadow border-0" style={{ padding: "16px 10px 0px 10px" }}>
          <div className="row cardFilters mt-1 mb-3">
            <div className="col-md-2">
              <div onClick={openPopupAdd}>
                <Link to={"/line-create"}>
                  <Button class="primaryBtn" variant="contained" endIcon={<ArticleIcon />} disabled={!isAuthenticated}>
                  Tạo Line
                  </Button>
                </Link>
              </div>
            </div>

            <div className=" col-md-10">
              <Box sx={{ minWidth: 120 }}>
                <FormProvider {...methods}>
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                    </Grid>

                    <Grid item xs={2}>
                      <RhfAutocomplete
                        name={"brandCD"}
                        // loading={isLoadingBrands}
                        options={[{ seq: 0, parent: "ROOT", brandCD: "ALL", brandName: "Tất cả" }, ...brands]}
                        keyValue={"brandCD"}
                        label={"Brand"}
                        getOptionLabel={(option) => option.brandName}
                        onChange={(option) => {
                          setSelectedBrand(option);
                        }}
                        value={selectedBrand}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Trạng thái line</InputLabel>
                        <Select
                          // size="small"
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="TRẠNG THÁI THANH TOÁN"
                          onChange={handleLineStatusChange}
                          defaultValue={"ALL"}
                        >
                          <MenuItem value={"ALL"}>Tất cả</MenuItem>
                          <MenuItem value={"I"}>Chưa đầy</MenuItem>
                          <MenuItem value={"F"}>Đã đầy</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item md={2}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Mút vợt</InputLabel>
                        <Select
                          defaultValue={"ALL"}
                          // size="small"
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="MÚT"
                          onChange={handleIsRubberChange}
                        >
                          <MenuItem value={"ALL"} >Tất cả</MenuItem>
                          <MenuItem value={"Y"}>Có mút</MenuItem>
                          <MenuItem value={"N"}>Không mút</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={1} alignItems={'center'}>

                      <FormControl>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value={selectedIsRubber}
                              onChange={(e) => setselectedIsMine(e.target.checked)}
                              defaultChecked
                            />
                          }
                          label="Của tôi"
                        />

                        {/* </RadioGroup> */}
                      </FormControl>
                      </Grid>
                      <Grid item xs={1} alignItems={'left'}>
                      <Button variant="contained" class="primaryBtn" color="primary"  endIcon={<ManageSearchIcon />} onClick={handleSearch}>Tìm</Button>
                      {/* 🔎 */}
                    </Grid>

                  </Grid>
                </FormProvider>
              </Box>
            </div>
          </div>

          <LineTable rows={lines} selectingLine={selectingLine} handleSearch={handleSearch} />

          <div className="d-flex tableFooter">
            <TablePagination
              count={total}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <img className="footer-bg" src="https://pingpong.id.vn/img/quytrinh.png" />
          </div>
        </div>
      </section>
    </>
  );
};

export default Line;
