import {
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import RegisterNodePopup from "components/line/RegisterNodePopup";
import RemoveLineNodePopup from "components/line/RemoveLineNodePopup";
import UnregistNodePopup from "components/line/UnregistNodePopup";
import { useHandleRegisterLineProgressNode } from "hooks/useHandleRegisterNode";
import { useHandleUpdateLineProgress } from "hooks/useHandleUpdateLineProgress";
import { useHandleUpdateNodeProgress } from "hooks/useHandleUpdateNodeProgress";


import { useNavigate } from "react-router-dom";
import { useHandle } from "hooks/useHandleUpdateLineProgress";
import { LineRow } from "./LineRow";
import UpdateNodeStatusPopup from "components/line/UpdateNodeStatusPopup";
import { useContext, useEffect, useState } from "react";
import { use } from "react";
import LoginModal from "components/login/LoginModal";
import useAuth from "hooks/useAuth";
import { AuthContext } from "providers/AuthProvider";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.primary.dark,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const LineTable = ({ rows = [], selectingLine, handleSearch }) => {
  const navigate = useNavigate();

  const [selectedItem, setSelectedItem] = useState(null);

  const handleChangeSelectedItem = (item) => {
    setSelectedItem(item);
  };
  
  const { 
    showModalPopupAdd, 
    showModalLogin, 
    setShowModalLogin,
    currentNode,
    setShowModalPopupAdd, 
    registerNewNode, 
    handleRegister, 
   } =
    useHandleRegisterLineProgressNode({
      onComplete: () => {
        //navigate(`/line/${currentNode.id}`);
      },
    });

  const { 
    selectedLine,
    showUpdateLineStatusPopup,
    updateLine,
    handleUpdateLine,
    setShowUpdateLineStatusPopup} = // , unregistNode, handleUnregist, currentNode
    useHandleUpdateLineProgress({
      onComplete: () => {
      },
    });



  const { setShowUpdateNodeStatusPopup, updateNodeStatus, showUpdateNodeStatusPopup, selectedNode, handleUpdateNodeStatus} =
  useHandleUpdateNodeProgress({
      onComplete: () => {
      },
    });

  const [hightLightItem, setHightLightItem] = useState(null);

  useEffect(() => {
    if (selectingLine) {
      setHightLightItem(selectingLine);
    }
  }, [selectingLine]);

  const handleSetHightLightItem = (id) => {
    setHightLightItem(id);
  };

  return (
    <TableContainer sx={{flex: 1}}>
      <Table sx={{position: "relative"}}>
        <TableHead sx={{position: "sticky", top: 0, zIndex: 11}}>
          <TableRow>
            <StyledTableCell width={"200px"}>
              <Typography variant="h6" sx={{ color: "primary.main" }}>
                Sản phẩm
              </Typography>
            </StyledTableCell>
            <StyledTableCell>
              <Typography variant="h6" sx={{ color: "primary.main" }}>
                Thông tin
              </Typography>
            </StyledTableCell>
          </TableRow>
        </TableHead>

        <TableBody style={{flex: 1}}>
          {rows?.map((row) => (
            <LineRow
              key={row.bladeUnitID}
              row={row}
              registerNewNode={registerNewNode}
              updateNodeStatus={updateNodeStatus}
              selectedNode={selectedNode}
              updateLine={updateLine}
              selectedLine={selectedLine}
              handleChangeSelectedItem={handleChangeSelectedItem}
              // Highlight
              hightLightItem={hightLightItem}
              setHightLightItem={handleSetHightLightItem}
            />
          ))}
          {!rows ||
            (rows.length === 0 && (
              <TableRow>
                <TableCell colSpan={2} align="center">
                  Không có sản phẩm
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>

      <RegisterNodePopup
        onClose={() => setShowModalPopupAdd(false)}
        open={showModalPopupAdd}
        currentNode={currentNode}
        handleRegister={async () => {
          await handleRegister();
          await handleSearch();
        }}
      />
{/* 
      <UnregistNodePopup
        onClose={() => setShowModalPopupUnregist(false)}
        open={showModalPopupUnregist}
        handleUnregist={() => {
          handleUnregist();
        }}
      /> */}

      <RemoveLineNodePopup
        onClose={() => setShowUpdateLineStatusPopup(false)}
        open={showUpdateLineStatusPopup}
        handleUpdateLine={() => {
          handleUpdateLine();
        }}
      />

      <UpdateNodeStatusPopup
        onClose={() => setShowUpdateNodeStatusPopup(false)}
        open={showUpdateNodeStatusPopup}
        handleUpdateNodeStatus={() => {
          handleUpdateNodeStatus();
        }}
      />  
      <LoginModal
        onClose={(isOK) => {
          setShowModalLogin(false);
          isOK && setShowModalPopupAdd(true);
        }}
        open={showModalLogin}
      />
    </TableContainer>
  );
};

export { LineTable };

