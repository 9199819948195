import { useState } from "react";
import { toast } from "react-toastify";
import useAuth from "./useAuth";
import { api } from "api";
import { updateNodeStatusForLineProgress } from "api/line";

export const useHandleUpdateNodeProgress = ({ onComplete = () => {} }) => {
  const { user } = useAuth();
  const [showUpdateNodeStatusPopup, setShowUpdateNodeStatusPopup] = useState(false);
  const [selectedNode, setSelectedNode] = useState(null);
  const [selectedAction, setSelectedAction] = useState(null);

  // const unregistNode = (data) => {
  //   setShowModalPopupUnregist(true);
  //   setCurrentNode(data);
  // };

  const updateNodeStatus = (data, action) => {
    setShowUpdateNodeStatusPopup(!showUpdateNodeStatusPopup);
    setSelectedNode(data);
    action && setSelectedAction(action);
  };
  

  // const handleUnregist = async (event) => {
  //   try {
  //     const responsePromise = unregist({
  //       email: user.email,
  //       bladeUnitID: currentNode.id,
  //       seq: currentNode.seq,
  //       token: "ABCXYZ",
  //     });

  //     const response = await toast.promise(responsePromise, {
  //       pending: "Promise is pending",
  //       success: "Hủy đăng ký thành công",
  //       error: "Hủy đăng ký thất bại",
  //     });

  //     onComplete({
  //       response,
  //       currentNode,
  //     });

  //     setShowModalPopupUnregist(false);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const handleUpdateNodeStatus = async (event) => {
    // return;
    try {      
      const responsePromise = updateNodeStatusForLineProgress(selectedNode.lpId,{
        email: selectedAction != "unregist" ? user.email : null,
        bladeUnitID: selectedNode.id,
        seq: selectedNode.seq,
        token: "ABCXYZ",
        lpId: selectedNode.lpId,
        status: "X",
        nodeProgressStatus: selectedAction != "unregist" ? selectedNode.nodeProgressStatus : 'C',
      });

      const response = await toast.promise(responsePromise, {
        pending: "Promise is pending",
        success: " thành công",
        error: "thất bại",
      });
      setShowUpdateNodeStatusPopup(!showUpdateNodeStatusPopup);
      onComplete({
        response,
        selectedNode,
      });
    } catch (error) {
      console.error(error);
    }
  };
  return {
    showUpdateNodeStatusPopup,
    handleUpdateNodeStatus,
    setShowUpdateNodeStatusPopup,
    updateNodeStatus,
    selectedNode,
    setCurrentNode: setSelectedNode,
  };
};
