import { AddAPhoto } from "@mui/icons-material";
import { Box, Card, CardContent, CardMedia, Grid, IconButton, TableCell, TableRow, Typography } from "@mui/material";
import { useState } from "react";
import LineMoneyBuilder from "./LineMoneyBuilder";
import useAuth from "hooks/useAuth";
import HoverImage from "pages/Line/HoverImage";
import { LineProcessBuilder } from "./LineProcessBuilder";


const LineBuilder = ({ data }) => {
	// Get current email
	const {
		isAuthenticated,
		user: { email },
	} = useAuth();
	const [price, setPrice] = useState(0);
	const imgTemp = "/upload/images/avatar-code.png";

	return (
		<TableRow key={data.name} sx={{width: '100%', display: 'flex'}}>
			<TableCell>
				<Card
					sx={{
						maxWidth: 300,
						borderRadius: "10px",
						margin: "auto",
						overflow: "visible"
					}}
				>
					<CardMedia
						component="img"
						image={data.bladeOrgImgUrl || "/upload/images/avatar-code.png"}
						alt="Blade Name"
						sx={{
							height: 105,
							objectFit: "contain",
						}}
					/>
					<CardContent sx={{ marginBottom: "0px !important", padding: "0px !important" }}>
						<Typography
							variant="h6"
							align="left"
							sx={{
								alignItems: "left",
								color: "#fff",
								backgroundColor: "#441752",
								fontWeight: "700",
								fontSize: "0.8rem",
								height: "40px",
								padding: "0px 2px",
								borderRadius: "5px",
							}}
						>
							{/* {`🗡 ${data?.bladeFullName}`}
							{` Giá gốc : 3.000.000 VND`} */}

							<div sx={{width: '100%'}}>{`🗡 ${data?.bladeFullName}`}</div>
							<div sx={{width: '100%'}}>{` Giá gốc : 3.000.000 VND`}</div>
						</Typography>
						<Typography
							variant="h6"
							align="left"
							sx={{
								alignItems: "left",
								color: "#fff",
								backgroundColor: "#AB4459",
								marginTop: "2px",
								fontWeight: "500 !important",
								fontSize: "0.8rem",
								padding: "0px 2px",
								borderRadius: "5px",
							}}
						>
							{`🏺 Độ mới: ${data?.remainingPercent || 100}% /`}{ data?.isRubber == 'N'? 'Không mút' : 'Có mút'}
						</Typography>
						<Typography
							variant="h6"
							align="left"
							sx={{
								alignItems: "left",
								color: "#fff",
								marginTop: "2px",
								backgroundColor: "#F29F58",
								fontWeight: "500 !important",
								fontSize: "0.8rem",
								padding: "0px 1px",
								borderRadius: "5px",
							}}
						>
							{`⏲ ${data?.periodCnt} Chu kỳ /  2 Tuần`}
						</Typography>
						<Grid container spacing={1} sx={{ marginTop: "0px", paddingTop: "0px !important" }}>
							{data.imageUrl.map((_, index) => (
								<Grid item xs={3} key={index} sx={{ marginTop: "0px", paddingTop: "0px !important" }}>
									<Box
										sx={{
											width: "100%",
											height: "40px",
											borderRadius: "2px",
											overflow: "hidden",
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
											border: "1px solid #ccc",

										}}
									>
										<HoverImage image={_} sx={{
											width: "30px",
											height: "30px",
										}} />
									</Box>
								</Grid>
							)).concat(
								[...Array((4 - data?.imageUrl.length) < 0 ? 0 : (4 - data?.imageUrl.length))].map((_, index) => (
									<Grid item xs={3} key={index} sx={{ marginTop: "0px", paddingTop: "0px !important" }}>
										<Box
											sx={{
												width: "100%",
												height: "40px",
												borderRadius: "2px",
												overflow: "hidden",
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
												border: "1px solid #ccc",
											}}
										>
											<IconButton
												sx={{
													width: 30,
													height: 30,
													borderRadius: 1.5,
													backgroundColor: "white",
													border: "1px solid #E0E0E0",
												}}
											>
												<AddAPhoto />
											</IconButton>
										</Box>
									</Grid>
								)))}
						</Grid>
					</CardContent>
				</Card>
			</TableCell>
			<TableCell sx={{flex: 1}}>
				<LineMoneyBuilder data={data} price={price} />
				<LineProcessBuilder 
					data={data} 
				/>
			</TableCell>
		</TableRow>
	);
};

export { LineBuilder };
