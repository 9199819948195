// ----------------------------------------------------------------------

export default function Table(theme) {
  return {
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: theme.palette.action.selected,
            "&:hover": {
              backgroundColor: theme.palette.action.hover
            }
          }
        }
      }
    },
    MuiTableCell: {
      defaultProps: {
        head: {
          align: "center"
        }
      },
      styleOverrides: {
        root: {
          // borderBottom: "none",
          [theme.breakpoints.down("lg")]: {
            padding: theme.spacing(1)
          },
          padding: theme.spacing(1)
        },
        head: {
          textAlign: "center",
          fontWeight: 600,
          fontSize: "0.8rem",
          color: theme.palette.text.secondary,
          backgroundColor: theme.palette.background.neutral,
          "&:first-of-type": {
            paddingLeft: theme.spacing(1),
            borderTopLeftRadius: theme.shape.borderRadius,
            borderBottomLeftRadius: theme.shape.borderRadius
          },
          "&:last-of-type": {
            paddingRight: theme.spacing(1),
            borderTopRightRadius: theme.shape.borderRadius,
            borderBottomRightRadius: theme.shape.borderRadius
          }
        },
        stickyHeader: {
          backgroundColor: theme.palette.background.paper,
          backgroundImage: `linear-gradient(to bottom, ${theme.palette.background.neutral} 0%, ${theme.palette.background.neutral} 100%)`
        },
        body: {
          "&:first-of-type": {
            paddingLeft: theme.spacing(1)
          },
          "&:last-of-type": {
            paddingRight: theme.spacing(1)
          }
        }
      }
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          borderTop: `solid 1px ${theme.palette.divider}`
        },
        toolbar: {
          height: 64
        },
        select: {
          "&:focus": {
            borderRadius: theme.shape.borderRadius
          }
        },
        selectIcon: {
          width: 20,
          height: 20,
          marginTop: 2
        }
      }
    }
  };
}
