import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material/";
// import "./PopupAddStyle.css";
import { Close } from "@mui/icons-material";

const UpdateNodeStatusPopup = ({ onClose, handleUpdateNodeStatus, open }) => {

    return (
        <Dialog open={open} maxWidth="lg">
            <DialogTitle>                
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Box>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Bạn muốn thay đổi trạng thái?
                    </Typography>
                </Box>
            </DialogContent>
            <DialogActions>                
                <Button variant="contained" onClick={handleUpdateNodeStatus}>
                    Xác nhận
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default UpdateNodeStatusPopup;
