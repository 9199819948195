import { useContext } from "react";
import Header from "./Header";
import { MyContext } from "../App";
import Sidebar from "./Sidebar";
import { Outlet, useNavigate, useRoutes } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { PUBLIC_URL } from "../router";

const AdminLayout = () => {



  const { isHideSidebarAndHeader, isToggleSidebar } = useContext(MyContext);

  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const path = window.location.pathname;

  // if (!isAuthenticated) {
  //   navigate("/login");
  // }

  // if (!PUBLIC_URL.includes(path) && !isAuthenticated) {
  //   console.log("isAuthenticated", isAuthenticated);
  //   // localStorage.setItem("jwtToken", "eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ0aGFuaHh1YW5AZ21haWwuY29tIiwiaWF0IjoxNzI0NzQ3NjYxLCJleHAiOjE3MjQ3NDkxMDF9.QVnEmsY9TL7tDxh6w0OOL7tl4w8w4cdiju4tyXWleyU");
  //   // localStorage.setItem("emailOrUsername", "anonymous@gmail.com");
  //   // localStorage.setItem("user", "{}");
  //   navigate("/login");
  // } else {
  //   // localStorage.setItem("jwtToken", "eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ0aGFuaHh1YW5AZ21haWwuY29tIiwiaWF0IjoxNzI0NzQ3NjYxLCJleHAiOjE3MjQ3NDkxMDF9.QVnEmsY9TL7tDxh6w0OOL7tl4w8w4cdiju4tyXWleyU");
  //   // localStorage.setItem("emailOrUsername", "anonymous@gmail.com");
  //   // localStorage.setItem("user", "{}");
  // }

  return (
    <>
      {isHideSidebarAndHeader !== true && <Header />}
      <div className="main d-flex app-content" >
        {isHideSidebarAndHeader !== true && (
          <div className={`sidebarWrapper ${isToggleSidebar === true ? "toggle" : ""}`}>
            <Sidebar />
          </div>
        )}

        <div
          className={`content  ${isHideSidebarAndHeader === true && "full"} ${
            isToggleSidebar === true ? "toggle" : ""
          }`}
        >
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default AdminLayout;
