import { yupResolver } from "@hookform/resolvers/yup";
import { Button, CardMedia, Chip, emphasize, Grid, InputAdornment, Paper, styled } from "@mui/material/";
import { useQuery } from "@tanstack/react-query";
import { getBladeByCodeAndRemainingPercent, getSearchBlades } from "api/blade";
import { getBrands } from "api/brand";
import { getCodesByStandardEnglishId } from "api/code";
import { useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FaCloudUploadAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { createLineFormData } from "../../api/line";
import { RhfAutocomplete, RhfSelect, RhfUploadMultipleFile } from "../../components/form";
import RhfTextField, { RhfNumber } from "../../components/form/RhfTextField";
import { isRubber, periodCounts, periods, registType } from "../../constants";
import { LineBuilder } from "./LineBuilder";
// Breadcrum code
const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor = theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
});
const RhfCurrency = ({ ...props }) => {
  return (
    <RhfNumber
      startAdornment={<InputAdornment position="start">$</InputAdornment>}
      endAdornment={<InputAdornment position="end">vnd</InputAdornment>}
      placeholder="0.00"
      thousandSeparator
      {...props}
    />
  );
};

const lineValidationSchema = yup.object({
  period: yup.string().required("Required"),
  periodCnt: yup.string().required("Required"),
  // lineReq: yup.string().required("Required"),
  // initPrice: yup.number().required("Required"),
});

const LineCreate = () => {
  const navigate = useNavigate();
  const { data: brands, isLoading: isLoadingBrands } = useQuery({
    queryKey: ["brandList"],
    queryFn: async () => {
      return getBrands();
    },
  });

  const dataBrand = brands?.data || [];

  const initDataMock = {
    name: "",
    bladeUnitID: "",
    bladeFullName: "",
    bladeOrgImgUrl: "",
    remainingPercent: 1,
    periodCnt: 0,
    imageUrl: [],
    bladeImageSet: [],
    bladeImageFull: "",
    listProgress: 0,
    registType: "L",
    priceVnd: 100000,
    depreciationAmount: 500,
    isRubber: "Y",
    fee: 10000,
    initPrice: 9,
  }

  const [selectedRegistType, setSelectedRegistType] = useState("L");
  const [selectedIsRubber, setselectedIsRubber] = useState('Y');
  const [stateImgFull, setStateImgFull] = useState(0);
  const [dataMock, setDataMock] = useState(initDataMock);

  const handleIsRubberChange = (event) => {
    setselectedIsRubber(event.target.value);
  };
  const handleRegistTypeChange = (event) => {
    setSelectedRegistType(event.target.value);
  };

  const methods = useForm({
    resolver: yupResolver(lineValidationSchema),
    defaultValues: {
      period: periods[0].value,
      periodCnt: periodCounts[0].value,
      // lineReq: lineReq[0].value,
      registType: selectedRegistType,
      remainingPercent: 0,
      isRubber: selectedIsRubber,
    }
  });

  const init = () => {
    const { setValue } = methods;
    setValue("depositAmount", '0');
    setValue("endPrice", '0');
    setValue("depreciationAmount", '0');
    setValue("priceVnd", '0');
    setValue("initPrice", '9');
    setStateImgFull('http://localhost:3000/upload/images/avatar-code.png')
  }

  const { handleSubmit, reset, setValue, watch } = methods;
  const watchBladeCD = watch(["bladeCD", "periodCnt", "remainingPercent", "registType", "isRubber", "initPrice"]);
  // const watchBladeCD = watch(["bladeCD", "periodCnt", "remainingPercent"]);
  const { data: bladeUnitRes, isLoading: isLoadingBladeUnit } = useQuery({
    queryKey: ["bladeUnit", watchBladeCD],
    queryFn: async () => {
      if (watchBladeCD[1]) {
        // debugger
        setDataMock({
          ...dataMock,
          listProgress: +watchBladeCD[1],
          periodCnt: +watchBladeCD[1],
          remainingPercent: watchBladeCD[2],
          registType: watchBladeCD[3],
          isRubber: watchBladeCD[4],
        })
      }

      return getBladeByCodeAndRemainingPercent(watchBladeCD[0],
        {
          remainingPercent: watchBladeCD[2],
          nodeCnt: watchBladeCD[1]
        }
      );

      //  queryFn: async () => {
      //   return getBladeByCode(watchBladeCD);

    },
  });
  const standardEnglishId = "REMAINING_PERCENT";
  const { data: codes, isLoading: isLoadingCodes } = useQuery({
    queryKey: ["code", standardEnglishId],
    queryFn: async () => {
      return getCodesByStandardEnglishId(standardEnglishId);
    },
  });
  const remainingPercents = useMemo(() => {
    const data = codes?.data || [];
    return data.map((item) => ({
      value: item.cdv,
      label: item.cdName,
    }));
  }, [codes]);

  const [selectedBrandCD, setSelectedBrandCD] = useState(null);
  const [selectedBladeCD, setSelectedBladeCD] = useState(null);
  const [selectedRemainingPercent, setSelectedRemainingPercent] = useState(null);
  const [loading, setLoading] = useState(false);
  const { data: blades, isLoading: isLoadingBlades } = useQuery({
    queryKey: ["bladeList", selectedBrandCD],
    queryFn: async () => {
      if (!selectedBrandCD) return [];
      const response = await getSearchBlades({ brandCD: selectedBrandCD });
      return response.data || [];
    },
    enabled: !!selectedBrandCD,
  });

  const bladeOptions = useMemo(() => {
    const result = blades?.map(blade => ({
      value: blade.bladeCD,
      label: blade.bladeName
    })) || [];
    if (result.length > 0) {
      setValue("bladeCD", result[0].value);
      setSelectedBladeCD(result[0].value);
      setDataMock({
        ...dataMock,
        bladeFullName: `${dataBrand.find(x => x.brandCD == selectedBrandCD).brandName} ${result[0].label}`,
      });
    }
    else {
      init();
    }
    return result;
  }, [blades, selectedBrandCD]);


  useEffect(() => {
    if (bladeUnitRes && bladeUnitRes?.data.length > 0 && selectedBladeCD) {
      ///debugger
      setValue("depositAmount", bladeUnitRes.data[0].depositAmount);
      setValue("endPrice", bladeUnitRes.data[0].endPrice);
      setValue("depreciationAmount", bladeUnitRes.data[0].depreciationAmount);
      setValue("priceVnd", bladeUnitRes.data[0].priceVnd);
      setStateImgFull(bladeUnitRes.data[0].imgFull);
      setDataMock({
        ...dataMock,
        bladeOrgImgUrl: bladeUnitRes.data[0].imgFull,
      });
    }
    else if (bladeUnitRes && bladeUnitRes?.data.length <= 0 && selectedBladeCD) {
      init();
    }
  }, [bladeUnitRes, selectedBladeCD]);

  useEffect(() => {
    if(watchBladeCD[5] && watchBladeCD[5] != '0'){
        setDataMock({
          ...dataMock,
          initPrice: watchBladeCD[5],
        });
    }
  }, [watchBladeCD[5]]);

  const onSubmit = async (data) => {
    const formData = new FormData();

    try {
      setLoading(true);
      data.email = localStorage.getItem("email");
      if (data.image != undefined && data.image != null) {
        const images = data.image;

        console.log(images);
        for (let i = 0; i < images.length; i++) {
          formData.append("images", images[i]);
        }
      } else {
        formData.append("images", null);
      }
      formData.append(
        "data",
        new Blob(
          [
            JSON.stringify({
              ...data,
              image: undefined,
            }),
          ],
          { type: "application/json" }
        )
      );

      const res = await createLineFormData(formData);
      toast.success("Create success");

      // window.location.href = "/line-list";
      navigate("/line-list");
    } catch (error) {
      console.error(error);
      toast.error("Create fail");
    } finally {
      setLoading(false);
    }
  };

  const handleOnchangeBrand = (option) => {
    setSelectedBrandCD(option.brandCD);
  }

  return (
    <>
      <section className="right-content w-100">
        <div className="card shadow border-0 p-3 mt-4">
          {/* <Paper sx={{ p: 2, display: "flex" }}>
            <h5 className="mb-0">
              <GrBladesHorizontal /> Line Create
            </h5>
            <Breadcrumbs aria-label="breadcrumb" className="ml-auto breadcrumbs_">
              <StyledBreadcrumb component="a" href="#" label="Dashboard" icon={<Home fontSize="small" />} />
              <StyledBreadcrumb component="a" href="#" label="Products" />
              <StyledBreadcrumb label="Line Create" />
            </Breadcrumbs>
          </Paper> */}

          <FormProvider {...methods}>
            <Paper component="form" sx={{ mt: 3, px: 2, py: 3 }} className="form" onSubmit={handleSubmit(onSubmit)}>
              {/* <h5 className="mb-4">
                <IoIosInformationCircleOutline /> Line Information
              </h5> */}

              <Grid container spacing={2} wrap="wrap">
                <Grid item md={6}>
                  <Grid container wrap="wrap" style={{ border: "1px solid #ccc", borderRadius: "8px", padding: "11px" }}>
                    <Grid item md={4}>
                      <RhfAutocomplete
                        name={"brandCD"}
                        loading={isLoadingBrands}
                        options={dataBrand}
                        keyValue={"seq"}
                        label={"Brand"}
                        getOptionLabel={(option) => option.brandName}
                        onChange={(option) => {
                          handleOnchangeBrand(option);
                        }}
                      />
                      <RhfAutocomplete sx={{ mt: 1 }}
                        name={"bladeCD"}
                        loading={isLoadingBlades}
                        options={bladeOptions}
                        // keyValue={"bladeCD"}
                        // label={"Blade"}
                        // getOptionLabel={(option) => option.bladeName}
                        onChange={(option) => {
                          setSelectedBladeCD(option.value);
                          setDataMock({
                            ...dataMock,
                            bladeFullName: `${dataBrand.find(x => x.brandCD == selectedBrandCD).brandName} ${option.label}`,
                          });
                        }}
                      />
                    </Grid>
                    <Grid item md={2}>
                      <RhfNumber name="depositAmount" label="Giá gốc" disabled={true} />
                      <RhfNumber name="initPrice" label="Giá thanh lý" sx={{ mt: 1 }}
                        disabled={false} />
                    </Grid>
                    <Grid item md={6}>

                      <RhfUploadMultipleFile hideRemoveAll shouldPreview={true} name="image" style={{ padding: '4px 8px' }} containerStyle={{ p: 0 }} previewStyle={{ width: 40, height: 40 }} />

                      <CardMedia
                      // component="img"
                      // image={stateImgFull || "/upload/images/avatar-code.png"} 
                      // alt="Super Viscaria"
                      // sx={{
                      //   height: 105,
                      //   objectFit: "contain",
                      // }}
                      />
                    </Grid>

                  </Grid>
                </Grid>
                <Grid item xs={3} md={4}>
                  <Grid container wrap="wrap" style={{ border: "1px solid #ccc", borderRadius: "8px", padding: "10px" }}>

                    <Grid item md={5}>
                      <RhfSelect name="periodCnt" label="Số lần trả" options={periodCounts} />

                      <RhfSelect
                        name="isRubber"
                        sx={{ mt: 2 }}
                        label="Mặt vợt"
                        options={isRubber}
                        onChange={handleIsRubberChange}
                        defaultValue={"Y"}
                      />
                    </Grid>
                    {/* <Grid item xs={12} md={6}>
                  <RhfSelect name="lineReq" label="Tình trạng" options={lineReq} />
                </Grid> */}
                    <Grid item md={7}>
                      <RhfSelect
                        name="registType"
                        label="Bán / Cho thuê"
                        options={registType}
                        onChange={handleRegistTypeChange}
                        defaultValue={"L"}
                      />
                      {/* <RhfCurrency sx={{ mt: 2 }} name="deposit" label="Có mút" /> */}
                      <RhfSelect sx={{ mt: 2 }} name="remainingPercent" label="Tình trạng vợt (%)" options={remainingPercents}
                        onChange={(option) => {
                          setSelectedRemainingPercent(option.value);
                        }} />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={2} md={2}>
                  <Grid container wrap="wrap" style={{ border: "1px solid #ccc", borderRadius: "8px", padding: "9px" }}>
                    <Grid item md={6}>
                      <RhfNumber name="initPrice" label="Khởi đầu" disabled={true} />
                      <RhfNumber sx={{ mt: 1.5 }} name="priceVnd" label="Phí sàn" disabled={true} />
                    </Grid>
                    <Grid item md={6}>
                      <RhfNumber name="endPrice" label="Kết thúc" disabled={true} />
                      <RhfNumber sx={{ mt: 1.5 }} name="depreciationAmount" label="Khấu hao" disabled={true} />
                    </Grid>
                  </Grid>
                </Grid>

                {/* <Grid item xs={12} md={6}>
                  <RhfDatePicker name="startDate" label={"Start Date"} />
                </Grid> */}
                <LineBuilder data={dataMock} />



                <Grid item xs={12}>
                  <RhfTextField name="note" label="Note" rows={2} />
                </Grid>
              </Grid>
              {/* <Grid item xs={12} md={8}>
                <RhfUploadMultipleFile shouldPreview={true} name="image" label={"Image"} />
              </Grid> */}
              <Grid item xs={12} md={4}>
              </Grid>
              <Button sx={{ mt: 3 }} type="submit" variant="contained" disabled={loading}>
                <FaCloudUploadAlt /> &nbsp; Create
              </Button>
            </Paper>
          </FormProvider>
        </div>
      </section>
    </>
  );
};

export default LineCreate;
