
import { getLinesOfUser } from "../../api/user";
import { useQuery } from "@tanstack/react-query";
import {
    Card,
    CardContent,
    CardHeader
} from "@mui/material/";

import useAuth from "hooks/useAuth";
import { LineTable } from "pages/Line/components/LineTable";

const LineSection = () => {
  const { user } = useAuth();
  const { data: lineDatas, isLoading } = useQuery({
    queryKey: ["lines", user?.email],
    queryFn: async () => {
      return user?.email && getLinesOfUser(user?.email);
    },
  });

  console.log("emailOrUsername", user, isLoading, lineDatas);
  const lines = lineDatas?.data?.data || [];

  return (
    <Card>
      <CardHeader title="Line Process" />
      <CardContent>
        <LineTable rows={lines} />
      </CardContent>
    </Card>
  );
};

export default LineSection;
