import { useTheme } from '@emotion/react';
import { AttachMoney, TrendingDown } from '@mui/icons-material';
import { Card, Stack, Typography } from '@mui/material';
import React, { useRef, useState } from 'react'
import { fCurrency } from 'utils';

const InfoItem = ({ icon, label, value }) => {
    const theme = useTheme();
    const ref = useRef();
   
    return (
      <Stack
        ref={ref}
        component={Card}
        sx={{ px: 2, py: 1, width: "100%", backgroundColor: theme.palette.grey[100] }}
        elevation={0}
        direction={"row"}
        gap={2}
      >
        {icon}
        <Typography variant="subtitle2" color={"GrayText"}>
          {label}
        </Typography>
        <Typography variant="subtitle1" fontWeight={"bold"}>
          {value}
        </Typography>
      </Stack>
    );
  };

function LineMoneyBuilder({ data, price }) {
  return (
    <Stack direction={"row"} spacing={1} justifyContent={"space-between"}>
      <InfoItem icon={<TrendingDown />} label={"Khấu hao"} value={fCurrency(data.depreciationAmount)} />
      <InfoItem icon={<AttachMoney />} label={"Phí sàn"} value={fCurrency(data.fee)} />    
      <InfoItem icon={<AttachMoney />} label={"Đặt cọc"} value={'-'} /> 
      <InfoItem icon={<AttachMoney />} label={"Hoàn cọc"} value={'-'} /> 
    </Stack>
  )
}

export default LineMoneyBuilder