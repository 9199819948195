import { useState } from "react";
import useAuth from "./useAuth";
import { api } from "api";
import { changeStatus } from "api/line";
import { toast } from "react-toastify";

export const useHandleUpdateLineProgress = ({ onComplete = () => {} }) => {
    const { user } = useAuth();
    const [selectedLine, setSelectedLine] = useState(null);
    const [showUpdateLineStatusPopup, setShowUpdateLineStatusPopup] = useState(false);
  
    const updateLine = (data) =>{
    setShowUpdateLineStatusPopup(!showUpdateLineStatusPopup);
    setSelectedLine(data);
    }

    const handleUpdateLine = async (data) => {
            // console.log("handleUpdateLine function");
            // console.log("Call api");
            try {
                const responsePromise = changeStatus(
                    selectedLine
                );

                const response = await toast.promise(responsePromise, {
                    pending: "Promise is pending",
                    success: "Cập nhật thành công",
                    error: "Cập nhật thất bại",
                });

                onComplete({
                    response,
                    selectedLine,
                });

                setShowUpdateLineStatusPopup(false);
            } catch (error) {
                console.error(error);       
        };
    }
    return {
        selectedLine,
        showUpdateLineStatusPopup,
        updateLine,
        handleUpdateLine,
        setShowUpdateLineStatusPopup
    };
};
