import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MyContext } from "../../App";
import Logo from "../../assets/images/logo-google.png";
import patern from "../../assets/images/pattern.webp";
import { getApiURL } from "../../common/utils/domainUtil";

import { Button, Dialog, DialogActions } from "@mui/material";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import { MdMail } from "react-icons/md";
import { RiLockPasswordFill } from "react-icons/ri";
import useAuth from "../../hooks/useAuth";

function LoginModal({ onClose, open }) {
    const [inputIndex, setInputIndex] = useState(null);
    const [isShowPassword, setIsShowPassword] = useState(false);
    const context = useContext(MyContext);

    useEffect(() => {
        context.setIsHideSidebarAndHeader(true);
    }, []);

    const focusInput = (index) => {
        setInputIndex(index);
    };

    const apiSignin = "public/signin";
    const apiURLSignin = getApiURL(apiSignin);

    const navigate = useNavigate();

    const [emailOrUsername, setEmailOrUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const { login, loginWithGoogle } = useAuth();

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const request = {
                emailOrUsername: emailOrUsername,
                password: password,
            };
            await login(emailOrUsername, password);

            // const res = await axios.post(':8080/api/v1/public/signin', request)

            setError("");
            navigate("/dashboard");
            // Redirect or perform any other actions
        } catch (error) {
            // Handle error
            setError("Invalid email or password");
            console.log(error);
        }
    };

    // const handleLoginGoogle = async (e) => {
    //   // Redirect to an external URL
    //   window.location.href = "8080/oauth2/authorization/google";
    // };

    const handleLoginWithGoogle = async (credentialResponse) => {
        const x = await loginWithGoogle(credentialResponse.credential);
        if (x === 1) {
            onClose(true);
        }
    };
    const handleLoginGoogle = useGoogleLogin({
        onSuccess: (codeResponse) => loginWithGoogle(codeResponse.access_token),
    });
    return (
        <Dialog open={open} maxWidth="lg">
            <img src={patern} className="loginPatern" />
            <section className="loginSection">
                <div className="loginBox">
                    <div className="logo text-center">
                        <img src={Logo} width="70px" />
                        <h5 className="font-weight-bold">Login to 탁구</h5>
                    </div>

                    <div className="wrapper mt-3 card border">
                        <form onSubmit={handleLogin}>
                            <div className={`form-group position-relative ${inputIndex === 0 && "focus"}`}>
                                <span className="icon">
                                    <MdMail />
                                </span>
                                <input
                                    type="text"
                                    name="emailOrUsername"
                                    className="form-control"
                                    placeholder="email or username"
                                    onFocus={() => focusInput(0)}
                                    onBlur={() => setInputIndex(null)}
                                    onChange={(e) => setEmailOrUsername(e.target.value)}
                                />
                            </div>

                            <div className={`form-group position-relative ${inputIndex === 1 && "focus"}`}>
                                <span className="icon">
                                    <RiLockPasswordFill />
                                </span>
                                <input
                                    type={`${isShowPassword === true ? "text" : "password"}`}
                                    name="password"
                                    className="form-control"
                                    placeholder="password"
                                    onFocus={() => focusInput(1)}
                                    onBlur={() => setInputIndex(null)}
                                    onChange={(e) => setPassword(e.target.value)}
                                />

                                <span className="toggleShowPassword" onClick={() => setIsShowPassword(!isShowPassword)}>
                                    {isShowPassword === true ? <IoMdEyeOff /> : <IoMdEye />}
                                </span>
                            </div>

                            <div className="form-group">
                                <Button type="submit" className="btn-blue btn-lg w-100 btn-big">
                                    Sign In
                                </Button>
                            </div>
                        </form>

                        <div className="form-group text-center mb-0">
                            <Link to={"/forgot-password"} className="link">
                                FORGOT PASSWORD
                            </Link>
                            <div className="d-flex align-items-center justify-content-center or mt-1">
                                <span className="line"></span>
                                <span className="txt">OR</span>
                                <span className="line"></span>
                            </div>
                            <GoogleLogin
                                onSuccess={(credentialResponse) => {
                                    handleLoginWithGoogle(credentialResponse);
                                }}
                                onError={() => {
                                    console.log("Login Failed");
                                }}
                            />
                        </div>
                    </div>

                    <div className="wrapper mt-3 card border footer p-3">
                        <span className="text-center">
                            Don't have account?
                            <Link to={"/sign-up"} className="link color ml-1">
                                Register
                            </Link>
                        </span>
                    </div>
                </div>
            </section>
            <DialogActions>
        <Button onClick={()=>onClose(false)} variant="outlined">
          Huỷ
        </Button>
      </DialogActions>
        </Dialog>
    )
}

export default LoginModal