import { useContext, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { MyContext } from "../../App";
import {
  Box,
  Breadcrumbs,
  Button,
  Chip,
  emphasize,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  styled,
  TableContainer,
} from "@mui/material/";
import { Home, ExpandMore } from "@mui/icons-material";

import { FaEye, FaPencilAlt, FaPlus } from "react-icons/fa";
import PopupAdd from "./components/PopupAdd";
import { getListPayment } from "api/payos";
import { TablePagination } from "components/table";

// Breadcrum code
const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor = theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
});

const TransactionReport = () => {
  // Check login
  // const navigate = useNavigate();
  // const token = localStorage.getItem("jwtToken");
  // if (token == null || token == undefined) navigate("/login");

  const [depositStatus, setDepositStatus] = useState('');
  const [searchData, setSearchData] = useState({
    email: "",
    depositStatus: "",
  });

  const context = useContext(MyContext);

  useEffect(() => {
    context.setIsHideSidebarAndHeader(false);
    window.scrollTo(0, 0);
  });

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const { data: listPayment } = useQuery({
    queryKey: ["paymentList", page, rowsPerPage, searchData],
    queryFn: async () => {
      return await getListPayment({
        pageNum: page,
        pageSize: rowsPerPage,
        ...searchData,
      });
    },
  });

  const { total, payments } = useMemo(() => {
    if (listPayment) {
      return { total: listPayment?.data.totalResults, payments: listPayment?.data.pageResults };
    }
    return { total: 0, payments: [] };
  }, [listPayment]);

  const [showModalPopupAdd, setShowModalPopupAdd] = useState(false);
  const openPopupAdd = () => {
    setShowModalPopupAdd(!showModalPopupAdd);
  };

  const handleSearch = (event) => {
    event.preventDefault();
    searchData.depositStatus = depositStatus;
  }

  const handleDepositStatusChange = (event) => {
    setDepositStatus(event.target.value);
    console.log(event.target.value)
  };

  // const [paymentData, setPaymentData] = useState(null);
  // useEffect(() => {
  //     const fetchData = async () => {
  //         try {
  //             const response = await axios.post(apiURLPayOSTransReportList, searchData);
  //             setListPayment(response.data);
  //         } catch (error) {
  //             console.error(error);
  //         }
  //     };
  //     fetchData();
  // }, []);

  // // Nếu brand có sub brand, setSubBranchVal
  // const handleClickDepositStatus = async (event) => {
  //     try {
  //         searchData.depositStatus = event.target.value;
  //         // const response = await axios.post(apiURLBrandSearch, searchData);
  //         // setBrandData(response.data);
  //         setStatusPayment(event.target.value);
  //     } catch (error) {
  //         console.error(error);
  //     }
  // };

  return (
    <>
      <section className="right-content w-100">
        <div className="card shadow border-0 w-100 flex-row p-4">
          <h5 className="mb-0">Báo Cáo Giao Dịch</h5>
          <Breadcrumbs aria-label="breadcrumb" className="ml-auto breadcrumbs_">
            <StyledBreadcrumb component="a" href="#" label="Dashboard" icon={<Home fontSize="small" />} />
            <StyledBreadcrumb label="Transaction" deleteIcon={<ExpandMore />} />
          </Breadcrumbs>
        </div>

        <div className="row dashboardBoxWrapperRow">
          <div className="col-md-8">
            <div className="dashboardBoxWrapper d-flex"></div>
          </div>

          <div className="col-md-4 pl-0">
            <div className="box"></div>
          </div>
        </div>

        <div className="card shadow border-0 p-3 mt-4">
          <div className="row cardFilters mt-3">
            <div className="col-md-4">
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <Button variant="contained" class ="primaryBtn searchBtn"  color="primary" onClick={handleSearch}>🔎 Tìm</Button>
                    </Grid>
                    <Grid item xs={8}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Trạng thái thanh toán</InputLabel>
                        <Select
                          // size="small"
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="TRẠNG THÁI THANH TOÁN"
                          onChange={handleDepositStatusChange}
                        >
                          <MenuItem value={"0"}>Tất cả</MenuItem>
                          <MenuItem value={"2"}>Đang chờ PENDING</MenuItem>
                          <MenuItem value={"3"}>Hủy CANCELLED</MenuItem>
                          <MenuItem value={"4"}>Đã thanh toán PAID</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </FormControl>
              </Box>
            </div>
          </div>

          <TableContainer className="table-responsive mt-3">
            <table className="table table-bordered v-align">
              <thead className="thead-dark">
                <tr>
                  <th>UID</th>
                  <th>LINE</th>
                  <th style={{ width: "300px" }}>MÃ ĐƠN HÀNG</th>
                  <th>TÊN KHÁCH HÀNG</th>
                  <th>SỐ TIỀN</th>
                  <th>TRẠNG THÁI</th>
                  <th>HÀNH ĐỘNG</th>
                </tr>
              </thead>

              <tbody>
                {payments && payments.length > 0 ? (
                  payments.map((item) => (
                    <tr>
                      <td>{item.id}</td>
                      <td>{item.lineIdSeq}</td>
                      <td>{item.orderCode}</td>
                      <td>{item.accountName}</td>
                      <td>{`${item.amount.toLocaleString()}`} VND</td>

                      {item.status === 1 ? (
                        <td>Mới</td>
                      ) : (item.status === 2) ? (
                        <td>Đang chờ</td>
                      ) : (item.status === 3) ? (
                        <td>Đã hủy</td>
                      ) : (item.status === 4) ? (
                        <td>Đã thanh toán</td>
                      ) : (
                        ""
                      )}

                      <td>
                        <div className="actions d-flex align-items-center">
                          <Link to={`/transaction-detail/${item.id}`}>
                            <Button className="secondary" color="secondary">
                              <FaEye />
                            </Button>
                          </Link>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr className="bladeBox">
                    <td colSpan="8">Không có sản phẩm</td>
                  </tr>
                )}
              </tbody>
            </table>

            <div className="d-flex tableFooter">
              <TablePagination
                count={total}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
            {showModalPopupAdd && (
              <PopupAdd onClose={() => setShowModalPopupAdd(false)} showModalPopupAdd={showModalPopupAdd} />
            )}
          </TableContainer>
        </div>
      </section>
    </>
  );
};

export default TransactionReport;
